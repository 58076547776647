import React, { useEffect, useState } from 'react';
import { IoCarSportOutline } from "react-icons/io5";

const Loader = () => {
    const [animationEnded, setAnimationEnded] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
          setAnimationEnded(false);
          setTimeout(() => setFadeOut(true), 1000);
        }, 3000); // 10 Sekunden

        const interval = setInterval(() => {
            setProgress(prev => Math.min(prev + 1, 100));
        }, 30);
      
        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (fadeOut) {
          const fadeOutTimer = setTimeout(() => setHidden(true), 3000); // Verstecke den Loader nach der Fade-Out-Animation
          return () => clearTimeout(fadeOutTimer);
        }
    }, [fadeOut]);
    
    if (hidden) {
        return null;
    }

    return (
        <div className={`fixed z-50 top-0 h-screen overflow-hidden bg-main-2 w-full ${fadeOut ? 'animate-fadeOut' : ''}`}>
            <div className="relative grid content-center h-full">
                <div className="mx-auto max-w-xs w-96">
                    {!animationEnded && 
                        <div className="grid place-content-center">
                            <p className="text-4xl flex items-center"><font className="font-playwritehr -ml-2">WaveCar</font> <IoCarSportOutline className="text-5xl ml-3" /></p>
                            <p className="text-lg mt-1 text-stone-300">Deine Fahrt, unsere Mission!</p>
                        </div>
                    }
                    <div className="bg-main-3 p-2 mt-3 rounded">
                        <div className="bg-neutral-200 h-1 rounded-sm" style={{ width: `${progress}%` }} />
                    </div>
                    <p className="text-center text-xs mt-2">
                        <b>{progress}%</b> geladen ...
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Loader;
