import React from "react";
import { IoCloseOutline } from "react-icons/io5";

const Modal = ({ isVisible, onClose, children, name }) => {
    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 bg-main-1 p-10 bg-opacity-95 overflow-auto no-scrollbar">
            <div className="flex items-center justify-between mb-5 border-b-[1px] border-main-2 pb-5">
                <p className="text-xl"><b>{name}</b></p>
                <button
                    className="bg-[#d9564c] hover:bg-[#d9564c]/75 duration-200 grid place-content-center text-white rounded w-8 h-8"
                    onClick={onClose}
                >
                    <IoCloseOutline className="text-2xl" />
                </button>
            </div>
            {children}
        </div>
    );
};

export default Modal;
