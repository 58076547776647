import React, { useState } from 'react';

const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="mb-2">
            <div 
                className="bg-main-2 hover:bg-main-3 duration-200 rounded-tl rounded-tr cursor-pointer p-5" 
                onClick={toggleAccordion}
            >
                {title}
            </div>
            {isOpen && (
                <div className="p-5 bg-main-3 rounded-bl rounded-br">
                    {content}
                </div>
            )}
        </div>
    );
};

const Accordion = () => {

    const items = [
        {
          title: 'Was ist WaveCar?',
          content: 'WaveCar ist ein Unternehmen, das sich auf Carsharing spezialisiert hat.'
        },
        {
          title: 'Wie funktioniert WaveCar?',
          content: 'WaveCar ermöglicht es den Nutzern, Autos für einen bestimmten Zeitraum zu mieten und nach Bedarf zu nutzen. Die Buchung erfolgt über die App des Unternehmens.'
        },
        {
          title: 'Welche Fahrzeuge bietet WaveCar an?',
          content: 'WaveCar bietet eine Auswahl an Fahrzeugen verschiedener Kategorien, darunter Kleinwagen, Limousinen und Elektroautos, je nach Standort und Verfügbarkeit.'
        },
        {
          title: 'Wo ist WaveCar verfügbar?',
          content: 'Die Verfügbarkeit von WaveCar kann je nach Standort variieren. Das Unternehmen operiert in ausgewählten Städten und Regionen.'
        },
        {
          title: 'Welche Vorteile bietet WaveCar gegenüber traditionellen Autovermietungen?',
          content: 'WaveCar ermöglicht eine flexible und bequeme Nutzung von Fahrzeugen ohne langfristige Verpflichtungen. Kunden können die Autos stunden- oder tageweise mieten und sparen sich damit die Kosten für den Besitz eines eigenen Autos.'
        },
        {
          title: 'Wie kann ich mich bei WaveCar registrieren?',
          content: 'Interessierte können sich in der Regel über die offizielle App von WaveCar registrieren. Dabei müssen üblicherweise persönliche Daten sowie Führerscheininformationen angegeben werden.'
        },
        {
          title: 'Gibt es eine Altersbeschränkung für die Nutzung von WaveCar?',
          content: 'Ja, in der Regel müssen Nutzer bei WaveCar ein Mindestalter erreichen und im Besitz eines gültigen Führerscheins sein, um die Dienste des Unternehmens nutzen zu können.'
        }
    ];

    return (
        <div className="mt-5">
            {items.map((item, index) => (
                <AccordionItem key={index} title={item.title} content={item.content} />
            ))}
        </div>
    );
};

export default Accordion;
